import * as React from 'react';
import ErrorPageContent from '../components/ErrorPageContent';

// markup
const EmailVerificationErrorPage = () => {
  return (
    <ErrorPageContent
      errorTitle={'メールアドレス認証ができませんでした'}
      errorMessage={
        'リンクの有効期限が切れている可能性があります。\n認証をやり直してください。'
      }
    />
  );
};

export default EmailVerificationErrorPage;
